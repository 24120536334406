svg {
  overflow: hidden;
  vertical-align: middle;
}

.MuiRating-label {
  margin-right: 5px;
}

#rating .MuiSvgIcon-root {
  font-size: 3rem !important;
}
