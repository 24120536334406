.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* height: 73.9vh; */
  width: 100%;
  background-color: white;
}

.now-container {
  /* height: 72.2vh; */
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 16px;
}

.p1 {
  position: absolute;
  top: 46vh;
  font-family: Poppins;
  font-size: 24px;
  font-weight: 400;
  text-align: center;
  margin: 0;
  color: #bfbfbf;
}

.p2 {
  position: absolute;
  top: 50vh;
  font-family: Poppins;
  font-size: 24px;
  font-weight: 400;
  text-align: center;
  margin: 0;
}

.p3 {
  position: absolute;
  top: 54vh;
  font-family: Poppins;
  font-size: 24px;
  font-weight: 400;
  text-align: center;
  margin: 0;
  color: #bfbfbf;
}

.action-btn {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.button {
  width: 50%;
  height: 50px;
  cursor: default;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 3.5%;
  font-family: Poppins;
}

.back-button {
  float: left;
  padding-top: 20px;
  padding-left: 2px;
  cursor: default;
  margin-left: 10px;
  margin-right: 10px;
}

.back-button:active {
  opacity: 0.7;
}

.button:active {
  opacity: 0.7;
}

.start-serving {
  min-width: 22%;
  border: 0px solid #bfbfbf;
  border-radius: 0px !important;
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
}

.parked-timer-text{
  position: absolute;
  right: 28px;
  text-align: right;
}
