.update-weight {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  float: right;
  padding: 5px;
}

.input-field {
  height: 15px;
}
