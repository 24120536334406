.container {
  display: flex;
  flex-direction: row;
  padding-top: 50px;
}

.field {
  display: flex;
  flex-direction: column;
}

.text_field {
  min-width: 300px;
}

.btn-kiosk{
  min-width: 220px;
  font-family: Poppins;
  margin-bottom: 14px;
}
