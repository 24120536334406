.report-search {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
.MuiInputBase-root {
  font-family: Poppins !important;
  font-size: 14px !important;
}

.MuiFormLabel-root{
  font-family: Poppins !important;
}