.lane-top { 
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 16px 6px 16px;
  background-color: #eeeeee;
  margin-bottom: 16px;
}

.counter-number{
  font-size: 24px;
  font-family: Poppins;
  color: #c2c2c2;
  position: absolute;
  right: 8px;
}

.timer-text{
  position: absolute;
  right: 16px;
  text-align: right;
}

.MuiChip-root{
  height: 24px !important;
  border-radius: 5px !important;
}