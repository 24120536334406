.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 300px;
  height: 300px;
  border: 1px solid #d3d3d3;
  border-radius: 0px;
  padding: 16px 16px 16px 16px;
  margin-left: 43%;
  margin-top: 12%;
}
